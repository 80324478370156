import { VehicleChargingStatus, VehicleStatus } from '@hiven-energy/hiven-client';
import { Progress, ProgressStepBarType } from '@hiven-energy/hiven-ui';
import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useAppTheme } from 'src/app-theme';
import { TogglePanel } from 'src/components/TogglePanel/TogglePanel';
import { DeviceList } from 'src/containers/DeviceList/DeviceList';
import { useScreenDimensions } from 'src/hooks/useScreenDimensions';
import { colors } from 'src/theme';

import {
  chargingStatusToIcon,
  chargingStatusToMessage,
  chargingStatusToProgressVariant,
  chargingStatusToStateOfChargeColor,
} from '../../constants';
import { ChargeLevels } from '../../types';

import * as styled from './styles';

type ProgressBarColors = Partial<Record<ProgressStepBarType, string>>;

interface Props {
  name: string | undefined;
  pairedChargerName?: string | undefined;
  lastHeartbeat?: string;
  chargeLevels: ChargeLevels;
  vehicleStatus: VehicleStatus | undefined;
  connectionPending: boolean;
  preferencesSet: boolean | undefined;
  deviceId: string | undefined;
  showDeviceList: boolean;
  onToggleDeviceList: VoidFunction;
}

const Status: FC<Props> = ({
  name,
  pairedChargerName,
  lastHeartbeat,
  chargeLevels,
  vehicleStatus,
  deviceId,
  connectionPending,
  preferencesSet,
  showDeviceList,
  onToggleDeviceList,
}) => {
  const intl = useIntl();
  const insets = useSafeAreaInsets();

  const appTheme = useAppTheme();

  const { soc, chargingStatus = VehicleChargingStatus.DISCONNECTED } = vehicleStatus || {};
  const [showContent, setShowContent] = useState(false);

  const socColor = chargingStatusToStateOfChargeColor[chargingStatus];
  const progressVariant = chargingStatusToProgressVariant[chargingStatus];
  const statusMessage = chargingStatusToMessage[chargingStatus];
  const StatusIcon = chargingStatusToIcon[chargingStatus];
  const [_, screenHeight] = useScreenDimensions();

  const progressBarColors = useMemo<ProgressBarColors>(
    () => ({
      [ProgressStepBarType.VALUE]:
        chargingStatus === VehicleChargingStatus.DISCONNECTED ? colors.pidgeonGray : undefined,
    }),
    [chargingStatus],
  );

  const formatPercents = (value: number) => intl.formatNumber(value / 100, { style: 'percent' });
  const title = name || '-';
  return (
    <styled.Container maxHeight={screenHeight}>
      <styled.StateContent insets={insets} backgroundColor={appTheme.main.color}>
        <styled.Label numberOfLines={1}>
          {pairedChargerName ? (
            <FormattedMessage id="ChargerDashboard.status.pairedWith" values={{ name: pairedChargerName }} />
          ) : (
            <FormattedMessage id="VehicleDashboard.status.label" />
          )}
        </styled.Label>
        <TogglePanel
          timestamp={lastHeartbeat}
          isActive={!!deviceId}
          title={title}
          toggleContent={deviceId && <DeviceList deviceId={deviceId} onPress={onToggleDeviceList} />}
          accessibilityLabel={title}
          analyticsPlace="VehicleDashboard.status"
          open={showDeviceList}
          onToggle={onToggleDeviceList}
          onStateChange={setShowContent}
        />
        {showContent && (
          <styled.Content>
            {connectionPending ? (
              <styled.Status>
                <FormattedMessage id="VehicleDashboard.status.connectionPending" />
              </styled.Status>
            ) : vehicleStatus ? (
              <>
                {soc !== undefined && (
                  <styled.StateOfCharge fontSize={50} fontWeight="bold" color={socColor}>
                    {formatPercents(soc)}
                  </styled.StateOfCharge>
                )}
                <styled.StatusRow>
                  <styled.IconLabelWrapper>
                    <StatusIcon size={20} color={colors.white} />
                    <styled.StatusLabel>
                      <FormattedMessage {...statusMessage} />
                    </styled.StatusLabel>
                  </styled.IconLabelWrapper>
                  {preferencesSet && (
                    <styled.TargetLabel fontSize={14}>
                      <FormattedMessage
                        id="VehicleDashboard.status.stopAt"
                        values={{ value: formatPercents(chargeLevels.maximum) }}
                      />
                    </styled.TargetLabel>
                  )}
                </styled.StatusRow>
                <Progress.Step
                  variant={progressVariant}
                  value={soc}
                  targetValue={chargeLevels.maximum}
                  barColors={progressBarColors}
                  animationDuration={150}
                />
              </>
            ) : (
              <styled.Status>
                <FormattedMessage id="VehicleDashboard.status.offline" />
              </styled.Status>
            )}
          </styled.Content>
        )}
      </styled.StateContent>
      <styled.RoundedBottom backgroundColor={appTheme.main.color} />
    </styled.Container>
  );
};

export default React.memo(Status);
