import { VehicleManufacturer } from '@hiven-energy/hiven-client';
import { useQueryClient } from '@tanstack/react-query';
import * as Linking from 'expo-linking';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import Loader from 'src/components/Loader/Loader';
import { useAppState } from 'src/hooks/useAppState';
import { useConnectSuccessTarget } from 'src/hooks/useConnectSuccessTarget';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useRegisterVehicle, useUserCountryCode } from 'src/queries/sdk';
import { VehicleManufacturerWithoutStartStop } from 'src/screens/brands/VehicleBrands/types';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { useHivenClient } from 'src/services/hiven';
import { Mode, useMode } from 'src/store/mode';
import { dismissBrowser, openBrowser } from 'src/utils/browser';

import Error from './Error/Error';
import Success from './Success/Success';

const CONNECT_REDIRECT_URL = Linking.createURL(RouteId.SmartCarConnect);

enum ConnectState {
  INITIAL = 'INITIAL',
  CONNECT = 'CONNECT',
  CANCEL = 'CANCEL',
  CODE = 'CODE',
  ADD_VIRTUAL_KEY = 'ADD_VIRTUAL_KEY',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface RoutedProps {
  manufacturer?: VehicleManufacturer | VehicleManufacturerWithoutStartStop;
  // SmartCar callback params
  code?: string;
  virtual_key_url?: string;
}

type Props = ScreenProps<RouteId.SmartCarConnect>;

const SmartCarConnect: FC<Props> = ({ route, navigation }) => {
  const { manufacturer, code, virtual_key_url: virtualKeyUrl } = route.params || {};

  const intl = useIntl();
  const toast = useToast();
  const appState = useAppState();
  const queryClient = useQueryClient();

  const [mode] = useMode();
  const hivenClient = useHivenClient();
  const { data: countryCode } = useUserCountryCode();
  const registerVehicleMutation = useRegisterVehicle();
  const successTarget = useConnectSuccessTarget();

  useAnalyticsTimeEvent(MixpanelEvents.VEHICLE_ADDED);

  const [connectState, setConnectState] = useState(ConnectState.INITIAL);

  useEffect(() => {
    if (manufacturer) {
      setConnectState(ConnectState.CONNECT);
    } else if (code) {
      setConnectState(ConnectState.CODE);
    } else {
      setConnectState(ConnectState.FAILURE);
    }
  }, [manufacturer, code]);

  useEffect(() => {
    if (Platform.OS === 'android' && appState === 'active' && connectState === ConnectState.CONNECT) {
      setConnectState(ConnectState.CANCEL);
    }
  }, [appState, connectState]);

  useEffect(() => {
    if (connectState === ConnectState.CANCEL) {
      navigation.navigate(RouteId.VehicleBrands);
    }
  }, [connectState]);

  useEffect(() => {
    if (connectState !== ConnectState.CONNECT) {
      dismissBrowser();
    }
  }, [connectState]);

  useEffect(() => {
    if (connectState === ConnectState.CONNECT) {
      performConnect(manufacturer!);
    }
  }, [connectState]);

  useEffect(() => {
    if (connectState === ConnectState.CODE) {
      performCodeExchange(code!);
    }
  }, [connectState]);

  useEffect(() => {
    if (connectState === ConnectState.ADD_VIRTUAL_KEY) {
      performVirtualKeyAdditionFlow(virtualKeyUrl!);
    }
  }, [connectState]);

  const performConnect = async (manufacturer: VehicleManufacturer | VehicleManufacturerWithoutStartStop) => {
    successTarget.saveTarget();
    let connectUrl: string;
    try {
      connectUrl = await getConnectUrl(manufacturer);
    } catch (_error) {
      toast.show(intl.formatMessage({ id: 'SmartCarConnect.authorizationUrl.fetch.error' }), {
        type: 'danger',
      });
      setConnectState(ConnectState.FAILURE);
      return;
    }
    const result = await openBrowser(connectUrl);
    if (result?.type === 'cancel') {
      setConnectState(ConnectState.CANCEL);
    }
  };

  const getConnectUrl = async (manufacturer: VehicleManufacturer | VehicleManufacturerWithoutStartStop) => {
    const { authorizationUrl } = await hivenClient.getVehicleAuthorizationUrl(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      manufacturer as any,
      CONNECT_REDIRECT_URL,
      countryCode || 'FI',
      mode === Mode.DEMO ? Mode.TEST : mode,
    );
    return decodeURIComponent(authorizationUrl);
  };

  const performCodeExchange = async (code: string) => {
    try {
      await registerVehicleMutation.mutateAsync({ code, redirectUrl: CONNECT_REDIRECT_URL });
      setConnectState(virtualKeyUrl ? ConnectState.ADD_VIRTUAL_KEY : ConnectState.SUCCESS);
    } catch (_error) {
      setConnectState(ConnectState.FAILURE);
    }
  };

  const performVirtualKeyAdditionFlow = async (virtualKeyUrl: string) => {
    await openBrowser(virtualKeyUrl, true);
    setConnectState(ConnectState.SUCCESS);
  };

  const handleContinuePress = () => {
    queryClient.invalidateQueries(['devices']);
    navigation.replace(...successTarget.getTarget());
  };

  const handleTryAgainPress = () => {
    navigation.navigate(RouteId.VehicleBrands);
  };

  const handleCancelPress = () => {
    navigation.navigate(RouteId.Initial);
  };

  const analytics = useMemo(
    () => ({
      DeviceType: 'vehicle',
      Code: code ? '<code>' : code,
      VirtualKeyUrl: virtualKeyUrl,
    }),
    [code, virtualKeyUrl],
  );

  if (connectState === ConnectState.SUCCESS) {
    return <Success analytics={analytics} virtualKeyUrl={virtualKeyUrl} onContinue={handleContinuePress} />;
  }

  if (connectState === ConnectState.FAILURE) {
    return <Error onTryAgain={handleTryAgainPress} onCancel={handleCancelPress} analytics={analytics} />;
  }

  return <Loader fullScreen />;
};

export default SmartCarConnect;
