import { UserLocation, VehicleChargingBehavior } from '@hiven-energy/hiven-client';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import ChargeLevels from 'src/containers/charge-levels/Preference/ChargeLevels';
import ChargingLocation from 'src/containers/charging-location/Preference/ChargingLocation';
import { ReadyTime } from 'src/containers/ready-time/Preference/ReadyTime';
import { ReadyTimeByType } from 'src/containers/ready-time/types';
import YourChargingLocations from 'src/containers/YourChargingLocations/YourChargingLocations';
import { useHeaderTitle } from 'src/hooks/useHeaderTitle';
import { RouteId, ScreenProps } from 'src/nav/types';
import {
  useSetOrUpdateUserLocation,
  useUpdateVehiclePreferences,
  useUserLocations,
  useVehicle,
  useVehiclePreferences,
} from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { Address, Region } from 'src/services/maps';
import { ErrorReason } from 'src/utils/queries';

import {
  CHARGE_LEVEL_LIMIT,
  CHARGE_LEVEL_UNIT,
  defaultRegion,
  initialPreferences,
  MINIMUM_CHARGE_LEVEL_HINT_THRESHOLD,
  vehiclePreferenceTitles,
} from '../constants';
import { Preferences as PreferencesSave, PreferenceType } from '../types';
import { createVehiclePreferences, hasId, parseLocation, parsePreferences, validateSaveConditions } from '../utils';

import ChargingBehavior from './ChargingBehavior/ChargingBehavior';
import Overview from './Overview/Overview';

export interface RoutedProps {
  deviceId: string;
  currentPreferenceType?: PreferenceType;
  updateOnPreferenceConfirm?: boolean;
}

type Props = ScreenProps<RouteId.VehiclePreferences>;

const Preferences: FC<Props> = ({ navigation, route: { params } }) => {
  const { deviceId, currentPreferenceType, updateOnPreferenceConfirm } = params;

  const intl = useIntl();
  const toast = useToast();

  useAnalyticsTimeEvent(MixpanelEvents.VEHICLE_PREFERENCES_SAVED);
  const { trackVehiclePreferencesChange, trackButtonClick } = useAnalytics();

  const [preferences, setPreferences] = useState<PreferencesSave>(initialPreferences);
  const [preferenceConfirmed, setPreferenceConfirmed] = useState(false);
  const deviceQuery = useVehicle(deviceId);
  const vehiclePreferencesQuery = useVehiclePreferences(deviceId);

  const { data: vehicle } = deviceQuery;
  const locationSupported = !!vehicle?.settings.locationSupported;

  const chargingLocationsQuery = useUserLocations({
    select: allLocations => {
      const chargingLocationIds = vehiclePreferencesQuery.data?.chargingLocationIds;
      return chargingLocationIds && chargingLocationIds.length
        ? allLocations.filter(({ id }) => chargingLocationIds.includes(id))
        : [];
    },
  });

  const setActivePreference = useCallback(
    (preferenceType: PreferenceType | undefined) => {
      navigation.navigate(RouteId.VehiclePreferences, { deviceId, currentPreferenceType: preferenceType });
    },
    [deviceId, navigation],
  );

  const preferencesMutation = useUpdateVehiclePreferences({
    onSuccess: (_, { preferences }) => {
      trackVehiclePreferencesChange(preferences, deviceId);
      navigation.goBack();
    },
    onError: () => {
      const message = intl.formatMessage({ id: 'VehiclePreferences.update.error' });
      toast.show(message, { type: 'danger' });
    },
  });

  const locationMutation = useSetOrUpdateUserLocation({
    onError: response => {
      if (response.status === ErrorReason.BAD_REQUEST) {
        toast.show(intl.formatMessage({ id: 'common.preferences.chargingLocation.update.address.error' }), {
          type: 'danger',
        });
      } else {
        toast.show(intl.formatMessage({ id: 'common.preferences.chargingLocation.update.tryAgain.error' }), {
          type: 'danger',
        });
      }
    },
  });

  useEffect(() => {
    if (deviceQuery.isSuccess && chargingLocationsQuery.isSuccess) {
      const vehiclePreferences = vehiclePreferencesQuery.data;
      const updatedPreferences = parsePreferences(vehiclePreferences, vehicle, chargingLocationsQuery.data);
      setPreferences(updatedPreferences);
    }
  }, [deviceQuery.isSuccess, chargingLocationsQuery.isSuccess, vehiclePreferencesQuery.isSuccess]);

  useEffect(() => {
    if (preferenceConfirmed) updatePreferences();
  }, [preferenceConfirmed]);

  const chargeLevelsTranslations = useMemo(
    () => ({
      'minimum.label': intl.formatMessage({ id: 'VehiclePreferences.chargeLevels.preference.minimum.label' }),
      'minimum.description': intl.formatMessage({
        id: 'VehiclePreferences.chargeLevels.preference.minimum.description',
      }),
      'maximum.label': intl.formatMessage({ id: 'VehiclePreferences.chargeLevels.preference.maximum.label' }),
      'maximum.description': intl.formatMessage({
        id: 'VehiclePreferences.chargeLevels.preference.maximum.description',
      }),
    }),
    [intl],
  );

  const handleGoBackPress = useCallback(() => {
    if (currentPreferenceType && !updateOnPreferenceConfirm) {
      resetActivePreference();
    } else if (deviceQuery.isError) {
      navigation.navigate(RouteId.Initial);
    } else {
      navigation.goBack();
    }
  }, [currentPreferenceType, updateOnPreferenceConfirm, deviceQuery.isError]);

  useHeaderTitle(
    intl.formatMessage(
      currentPreferenceType
        ? vehiclePreferenceTitles[currentPreferenceType]
        : { id: 'navigation.screen.VehiclePreferences' },
    ),
    handleGoBackPress,
  );

  const resetActivePreference = () => {
    setActivePreference(undefined);
  };

  const updatePreferences = async () => {
    try {
      const locations = await updateLocations(preferences.chargingLocations);
      updateVehiclePreferences(locations);
    } catch (_error) {
      return;
    }
  };

  const updateLocations = async (locations: PreferencesSave['chargingLocations']) =>
    Promise.all(
      locations.map(location =>
        locationMutation.mutateAsync({
          id: location.id,
          payload: location.payload,
        }),
      ),
    );

  const updateVehiclePreferences = (locations: UserLocation[]) => {
    const vehiclePreferences = createVehiclePreferences(preferences, locations);
    preferencesMutation.mutateAsync({ deviceId, preferences: vehiclePreferences });
  };

  const handleAddChargingLocationConfirm = (region: Region, address: Required<Address>, name: string) => {
    setPreferences(preferences => ({
      ...preferences,
      chargingLocations: [
        ...preferences.chargingLocations,
        {
          payload: { name, coordinates: region, ...address, tariffInfo: undefined },
        },
      ],
    }));
    handlePreferenceConfirm();
  };

  const handleChargingLocationsConfirm = (locations: UserLocation[]) => {
    setPreferences(preferences => ({
      ...preferences,
      chargingLocations: [...preferences.chargingLocations.filter(({ id }) => !id), ...locations.map(parseLocation)],
    }));
    handlePreferenceConfirm();
  };

  const handleAddNewLocationPress = () => {
    setActivePreference(PreferenceType.ADD_CHARGING_LOCATION);
  };

  const handleChargeLevelsConfirm = (minimum: number, maximum: number) => {
    setPreferences(preferences => ({
      ...preferences,
      chargeLevels: { minimum, maximum },
    }));
    handlePreferenceConfirm();
  };

  const handleChargingBehaviorConfirm = (value: VehicleChargingBehavior) => {
    setPreferences(preferences => ({
      ...preferences,
      chargingBehavior: value,
    }));
    handlePreferenceConfirm();
  };

  const handleReadyTimeConfirm = (readyTime: ReadyTimeByType) => {
    setPreferences(preferences => ({
      ...preferences,
      readyTime,
    }));
    handlePreferenceConfirm();
  };

  const handleRemoveNewLocation = (index: number) => {
    setPreferences(preferences => ({
      ...preferences,
      chargingLocations: [
        ...preferences.chargingLocations.slice(0, index),
        ...preferences.chargingLocations.slice(index + 1),
      ],
    }));
  };

  const handlePreferenceConfirm = () => {
    if (updateOnPreferenceConfirm) {
      setPreferenceConfirmed(true);
    } else {
      resetActivePreference();
    }
  };

  const handleSavePress = () => {
    trackButtonClick('common.save');
    updatePreferences();
  };

  const saveAllowed = validateSaveConditions(preferences, deviceQuery, chargingLocationsQuery);
  const saving = locationMutation.isLoading || preferencesMutation.isLoading;

  if (currentPreferenceType === PreferenceType.ADD_CHARGING_LOCATION) {
    return (
      <ChargingLocation
        region={defaultRegion}
        analyticsPlace="VehiclePreferences.chargingLocation"
        onConfirm={handleAddChargingLocationConfirm}
      />
    );
  }

  if (currentPreferenceType === PreferenceType.YOUR_CHARGING_LOCATIONS) {
    return (
      <YourChargingLocations
        selectMultiple
        selectedLocationsIds={preferences.chargingLocations.filter(hasId).map(({ id }) => id)}
        saving={saving}
        onConfirm={handleChargingLocationsConfirm}
        onAddNewLocation={handleAddNewLocationPress}
      />
    );
  }

  if (currentPreferenceType === PreferenceType.READY_TIME) {
    return <ReadyTime initialTime={preferences.readyTime} saving={saving} onConfirm={handleReadyTimeConfirm} />;
  }

  if (currentPreferenceType === PreferenceType.CHARGE_LEVELS) {
    return (
      <ChargeLevels
        minimum={preferences.chargeLevels.minimum}
        maximum={preferences.chargeLevels.maximum}
        unit={CHARGE_LEVEL_UNIT}
        limit={CHARGE_LEVEL_LIMIT}
        minimumHintThreshold={MINIMUM_CHARGE_LEVEL_HINT_THRESHOLD}
        translations={chargeLevelsTranslations}
        saving={saving}
        onConfirm={handleChargeLevelsConfirm}
      />
    );
  }

  if (currentPreferenceType === PreferenceType.CHARGING_BEHAVIOR) {
    return (
      <ChargingBehavior
        locationSupported={locationSupported}
        value={preferences.chargingBehavior}
        saving={saving}
        onConfirm={handleChargingBehaviorConfirm}
      />
    );
  }

  return (
    <Overview
      preferences={preferences}
      onRemoveNewLocation={handleRemoveNewLocation}
      saveAllowed={saveAllowed}
      saving={saving}
      initialLoading={deviceQuery.isInitialLoading}
      onEditPreferencePress={setActivePreference}
      onSavePress={handleSavePress}
      isAssociated={!!vehicle?.associatedToCharger}
    />
  );
};

export default Preferences;
